<template>

  <v-container fluid>
    <v-row v-if="!loading.collegeData && collegeData">
      <v-col
        cols="12"
        sm="9"
      >

        <template v-if="loading.overAll">
          <base-wee-sketlon-loader
            :loading="loading.overAll"
            :no="1"
          />
        </template>
        <v-row v-else>
          <v-col cols="12">

            <overall-percentage
              v-if="overall"
              :data="overall"
              :currentdate="currentDatetime"
              v-model="overall.percentage"
              :dark="currentTheme.darkMode"
              :college-data="collegeData"
              :lang="currentLanguge"
              :charts="chartAll"
              :categories="chartCategories"
              :chart-update="chartAllUpdate"
            />

            <v-divider></v-divider>
          </v-col>
        </v-row>

        <template v-if="loading.userType">
          <base-wee-sketlon-loader
            :loading="loading.userType"
            :no="3"
            :cols="4"
          />
        </template>
        <v-row v-else>
          <v-col cols="12">
            <!-- <v-card> -->
            <v-row>

              <v-col
                cols="12"
                sm="4"
              >
                <realtime-chart-by-type
                  :type="1"
                  :data="student"
                  :charts="chartStudent"
                  :categories="chartCategories"
                  v-model="chartStudentUpdate"
                  :dark="currentTheme.darkMode"
                />
              </v-col>

              <v-col
                cols="12"
                sm="4"
              >
                <realtime-chart-by-type
                  :type="2"
                  :data="teacher"
                  :charts="chartTeacher"
                  :categories="chartCategories"
                  v-model="chartTeacherUpdate"
                  :dark="currentTheme.darkMode"
                />

              </v-col>
              <v-col
                cols="12"
                sm="4"
              >
                <realtime-chart-by-type
                  :type="3"
                  :data="guest"
                  :charts="chartGuest"
                  :categories="chartCategories"
                  v-model="chartGuestUpdate"
                  :dark="currentTheme.darkMode"
                />
              </v-col>

            </v-row>
            <!-- </v-card> -->
          </v-col>
        </v-row>

      </v-col>

      <!-- Lastest checkin checkout -->
      <v-col
        cols="12"
        sm="3"
        class="py-0 my-0"
      >
        <!-- Check in  -->
        <v-subheader class="wee-text-muted">
          <v-icon
            small
            class="mr-2"
          >mdi-account-arrow-left</v-icon>{{$t('app.lastestCheckin')}}
        </v-subheader>
        <template v-if="loading.latestCheckin">
          <base-wee-sketlon-loader
            :loading="loading.latestCheckin"
            type="list-item-avatar-two-line"
            :no="1"
            list
          />
        </template>
        <LastestCheck
          v-else
          checkin
          :datas="checkinLatest"
          style="height:300px;overflow-y: scroll;"
        />

        <!-- Checkout  -->
        <v-subheader class="wee-text-muted">
          <v-icon
            small
            class="mr-2"
          >mdi-account-arrow-right</v-icon>{{$t('app.lastestCheckout')}}
        </v-subheader>
        <template v-if="loading.latestCheckout">
          <base-wee-sketlon-loader
            :loading="loading.latestCheckout"
            type="list-item-avatar-two-line"
            :no="1"
            list
          />
        </template>
        <LastestCheck
          v-else
          :checkin="false"
          :datas="checkoutLatest"
          style="height:300px;overflow-y: scroll;"
        />
      </v-col>

    </v-row>
    <template v-else-if="!loading.collegeData && !collegeData">
      <wee-error :image="true" />
    </template>

    <wee-confirm ref="weeConfirmRef"></wee-confirm>
    <wee-toast ref="weeToastRef"></wee-toast>
    <base-wee-loader v-model="loading.collegeData" />
  </v-container>
</template>

<script>
import useBase from "@/composition/UseBase";
import useSocket from "@/composition/UseSocket";
import useSiteSetting from "@/composition/UseSiteSetting";
import { DefaultSocketKey, SocketTopic } from "@/plugins/config";
import {
  onMounted,
  onBeforeUnmount,
  ref,
  reactive,
  toRefs
} from "@vue/composition-api";
import EdrColleageService from "@/api/EdrColleageService";
import EdrCheckinService from "@/api/EdrCheckinService";
// import SocketService from "@/api/SocketService";
export default {
  components: {
    WeeConfirm: () => import("@/components/WeeConfirm"),
    WeeToast: () => import("@/components/WeeToast"),
    OverallPercentage: () => import("./components/OverallPercentage"),
    RealtimeChartByType: () => import("./components/RealtimeChartByType"),
    LastestCheck: () => import("./components/LastestCheck"),
    WeeError: () => import("@/components/WeeError")
  },
  setup(props, { refs, root }) {
    const { toast } = useBase(refs, root);
    const { $route } = root;
    const collegeCode = ref(null);
    const state = reactive({
      overall: null,
      student: null,
      guest: null,
      teacher: null,
      checkinLatest: [],
      checkoutLatest: [],
      currentDatetime: null
    });

    const loading = reactive({
      collegeData: false,
      overAll: true,
      userType: true,
      latestCheckin: true,
      latestCheckout: true
    });
    //chart data
    const chartState = reactive({
      chartCategories: [],
      chartAll: null,
      chartAllUpdate: null,
      chartStudent: null,
      chartStudentUpdate: null,
      chartGuest: null,
      chartGuestUpdate: null,
      chartTeacher: null,
      chartTeacherUpdate: null
    });

    const collegeData = ref(null);
    const { currentLanguge, currentTheme } = useSiteSetting(root);
    const edrColleageService = new EdrColleageService();
    const edrCheckinService = new EdrCheckinService();

    //Socket
    const socket = reactive({
      openSockets: null,
      closeSockets: null
    });

    onMounted(async () => {
      collegeCode.value = $route.params.collegeCode;

      const loadCollegeState = await loadColegeData();
      if (loadCollegeState) {
        await getCheckinTrackingCurrent();
        await getCheckinTrackingUserTypeChart();
        await getCheckinTrackingLatest();

        const socketSetState = await setupSocket();
        if (socketSetState) {
          //close socket
          if (socket.openSockets) {
            socket.openSockets();
          }
        }
      }
    });

    //Socket
    const onSocketsEvent = args => {
      const response = args[0];
      if (response) {
        if (
          response.broadcastReloadSocketRealtime &&
          response.broadcastReloadSocketRealtime === true
        ) {
          window.location.reload();
          return;
        }

        setCountData(response);
        //set chart update
        if (response.chartUpdate) {
          chartState.chartAllUpdate = response.chartUpdate.chartAllUpdate
            ? response.chartUpdate.chartAllUpdate
            : null;

          chartState.chartStudentUpdate = response.chartUpdate
            .chartStudentUpdate
            ? response.chartUpdate.chartStudentUpdate
            : null;

          chartState.chartTeacherUpdate = response.chartUpdate
            .chartTeacherUpdate
            ? response.chartUpdate.chartTeacherUpdate
            : null;

          chartState.chartGuestUpdate = response.chartUpdate.chartGuestUpdate
            ? response.chartUpdate.chartGuestUpdate
            : null;
          //set latest chec-in out
          if (response.latestItem) {
            if (response.isCheckIn) {
              if (state.checkinLatest.length == 5) {
                state.checkinLatest.splice(state.checkinLatest.length - 1, 1);
              }
              state.checkinLatest.unshift(response.latestItem);
            } else {
              if (state.checkinLatest.length == 5) {
                state.checkoutLatest.splice(state.checkoutLatest.length - 1, 1);
              }
              state.checkoutLatest.unshift(response.latestItem);
            }
          }

          state.checkinLatest = response.latestCheck.latestCheckin
            ? response.latestCheck.latestCheckin
            : [];
          state.checkoutLatest = response.latestCheck.latestCheckout
            ? response.latestCheck.latestCheckout
            : [];
        }
      }
    };
    const setupSocket = () => {
      return new Promise(resove => {
        const { openSockets, closeSockets } = useSocket(
          collegeData.value.sockets_path,
          DefaultSocketKey,
          SocketTopic.MONITOR_CHECK_IN + collegeData.value.college_code,
          onSocketsEvent
        );

        socket.openSockets = openSockets;
        socket.closeSockets = closeSockets;
        resove(true);
      });
    };
    //End Socket

    const loadColegeData = async () => {
      loading.collegeData = true;
      const { response, error } = await edrColleageService.edrColleageByCode(
        collegeCode.value
      );
      loading.collegeData = false;
      let resault = false;
      if (error) {
        toast(false, error, { timeout: 5000 });
        return new Promise(resove => {
          resove(false);
        });
      }
      toast(response.status, response.message, { timeout: 5000 });
      if (response && response.entity) {
        collegeData.value = response.entity;
        resault = true;
      }
      return new Promise(resove => {
        resove(resault);
      });
    };

    const getCheckinTrackingCurrent = async () => {
      const {
        response,
        error
      } = await edrCheckinService.checkinTrackingCurrent(collegeData.value);

      loading.overAll = false;
      if (error) {
        toast(false, error, { timeout: 5000 });
        return new Promise(resove => {
          resove(true);
        });
      }
      toast(response.status, response.message, { timeout: 5000 });
      if (response && response.data) {
        setCountData(response);
      }
      return new Promise(resove => {
        resove(true);
      });
    };

    const setCountData = response => {
      state.overall = response.data.overall ? response.data.overall : null;
      state.student = response.data.student ? response.data.student : null;
      state.teacher = response.data.teacher ? response.data.teacher : null;
      state.guest = response.data.guest ? response.data.guest : null;
      state.currentDatetime = response.currentDatetime
        ? response.currentDatetime
        : null;
    };

    const getCheckinTrackingUserTypeChart = async () => {
      const {
        response,
        error
      } = await edrCheckinService.checkinTrackingUserTypeChart(
        collegeData.value
      );

      loading.userType = false;
      if (error) {
        toast(false, error, { timeout: 5000 });
        return new Promise(resove => {
          resove(true);
        });
      }
      toast(response.status, response.message, { timeout: 5000 });
      if (response) {
        chartState.chartCategories = response.categories
          ? response.categories
          : [];

        chartState.chartAll = response.all ? response.all : [];
        chartState.chartStudent = response.student ? response.student : [];
        chartState.chartTeacher = response.teacher ? response.teacher : [];
        chartState.chartGuest = response.guest ? response.guest : [];
      }
      return new Promise(resove => {
        resove(true);
      });
    };

    const getCheckinTrackingLatest = async () => {
      const {
        response,
        error
      } = await edrCheckinService.checkinTrackingLatestCheck(collegeData.value);

      loading.latestCheckin = false;
      loading.latestCheckout = false;
      if (error) {
        toast(false, error, { timeout: 5000 });
        return new Promise(resove => {
          resove(true);
        });
      }
      toast(response.status, response.message, { timeout: 5000 });
      if (response) {
        state.checkinLatest = response.latestCheckin
          ? response.latestCheckin
          : [];
        state.checkoutLatest = response.latestCheckout
          ? response.latestCheckout
          : [];
      }
      return new Promise(resove => {
        resove(true);
      });
    };

    onBeforeUnmount(() => {
      //close socket
      if (socket.closeSockets) {
        socket.closeSockets();
      }
    });

    return {
      ...toRefs(state),
      ...toRefs(chartState),
      currentLanguge,
      currentTheme,
      collegeData,
      toast,
      loading
    };
  }
};
</script>

<style></style>

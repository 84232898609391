// import { reactive, toRefs, ref } from "@vue/composition-api";
export default (socketPath, realmKey, topic, onSocketsEvent)=> {
    const autobahn = require("autobahn");
    let socketService = null;
    const openSockets = () => {
        // console.log('socketPath: ' + socketPath, 'realmKey: ' + realmKey, 'topic: ' + topic)
        //socket
        socketService = new autobahn.Connection({
            url: socketPath,
            realm: realmKey
        });
        socketService.onopen = (session) => {
            // 1) subscribe to a topic
            // function onevent(args) {
            //   console.log("Event:", args[0]);
            // }
            // var counter = 0;
            session.subscribe(topic, onSocketsEvent);

            // 2) publish an event
            // session.publish("com.myapp.hello", [{ "key ": "Hello, world!" }]);

            /*
            // 3) register a procedure for remoting
            function add2(args) {
              return args[0] + args[1];
            }
            session.register("com.myapp.add2", add2);

            // // 4) call a remote procedure
            session.call("com.myapp.add2", [2, 3]).then(function(res) {
              console.log("Result:", res);
            });
            */

        };
        socketService.open();
        // console.log(socketService)
    };

    const closeSockets = () => {
        //close sockets
        if (socketService) {
            socketService.close();
            socketService = null;
        }
    };
    return { openSockets, closeSockets };
}
